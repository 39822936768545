<template>
  <v-container id="clients" fluid tag="section">
    <v-data-table :headers="headers" :items="clients" :search="search" sort-by="id" :sort-desc="true"
      :no-data-text="str['clients_empty']" class="elevation-1" @click:row="openClient">
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
        </v-toolbar>
      </template>
      <template v-slot:item.accept_terms_date="{ item }">
        {{ item.accept_terms_date ? item.accept_terms_date : str['no'] }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="item.status !== statusMap.active" small class="mr-2" color="success"
          @click.stop="updatePaymentStatus(item, statusMap.active, 'want_activate_client')">
          mdi-account-check
        </v-icon>
        <v-icon v-if="item.status !== statusMap.suspended" small class="mr-2" color="warning"
          @click.stop="updatePaymentStatus(item, statusMap.suspended, 'want_suspend_client')">
          mdi-lock
        </v-icon>
        <v-icon v-if="item.status !== statusMap.blocked" small class="mr-2" color="error"
          @click.stop="updatePaymentStatus(item, statusMap.blocked, 'want_block_client')">
          mdi-block-helper
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '../../../services/Api'
import Utils from '../../../services/Utils'

export default {
  data() {
    return {
      str: window.strings,
      clients: [],
      search: '',
      headers: [
        { text: window.strings['id'], value: 'id', width: 50 },
        { text: window.strings['name'], value: 'name', align: 'center', width: 150 },
        { text: window.strings['email'], value: 'email', align: 'center', width: 200 },
        { text: window.strings['clients'], value: 'clients', align: 'center', width: 100 },
        { text: window.strings['monthly_payment'], value: 'payment_amount', align: 'center', width: 120 },
        { text: window.strings['payment'], value: 'payment_status', align: 'center', width: 120 },
        { text: window.strings['registration_created_date'], value: 'register_date', align: 'center', width: 160 },
        { text: window.strings['terms_and_conditions'], value: 'accept_terms_date', align: 'center', width: 160 },
        { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 100 },
      ],
      statusMap: {
        active: 0,
        suspended: 1,
        blocked: 2,
      },
    }
  },
  beforeMount: function () {
    this.getClients()
  },
  methods: {
    getClients: function () {
      const self = this
      this.$isLoading(true)

      // Função reutilizável para buscar clientes
      const fetchClient = (id, callback) => {
        Api.getActiveClients(id, {}, (resp) => {
          if (resp.success) {
            callback(resp)
          } else {
            self.$isLoading(false)
            self.$alert(resp.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }

      // Obter clientes do servidor comum
      Api.getActiveClients(false, {}, (response) => {
        if (response.success) {
          // Buscar ptTg e ptFm simultaneamente
          const ptTgPromise = new Promise((resolve) => {
            fetchClient(window.ptTgId, (respTg) => resolve(respTg.data.find(p => p.id === window.ptTgId)))
          })

          const ptFmPromise = new Promise((resolve) => {
            fetchClient(window.ptFmId, (respFm) => resolve(respFm.data.find(p => p.id === window.ptFmId)))
          })

          Promise.all([ptTgPromise, ptFmPromise]).then(([ptTg, ptFm]) => {
            let foundTg = false
            let foundFm = false

            // Atualizar ou adicionar ptTg e ptFm aos dados
            response.data.forEach((client, index) => {
              if (client.id === window.ptTgId) {
                response.data[index] = ptTg
                foundTg = true
              }
              if (client.id === window.ptFmId) {
                response.data[index] = ptFm
                foundFm = true
              }
            })

            // Se ptTg ou ptFm não estiverem na lista, adicioná-los
            if (!foundTg) response.data.push(ptTg)
            if (!foundFm) response.data.push(ptFm)

            // Chama a função para buscar os últimos pagamentos
            self.getLastPayments(response.data)
          })
        } else {
          self.$isLoading(false)
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      });
    },
    getLastPayments: function (pts) {
      const self = this
      this.$isLoading(true)

      // Função reutilizável para buscar pagamentos com base no ID
      const fetchPayments = (id, callback) => {
        Api.getCurrentPayments(id, (resp) => {
          if (resp.success) {
            callback(resp)
          } else {
            self.$isLoading(false)
            self.$alert(resp.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }

      // Obter pagamentos atuais
      fetchPayments(false, (response) => {
        if (response.success) {
          // Buscar pagamentos de ptTg e ptFm simultaneamente
          const ptTgPromise = new Promise((resolve) => {
            fetchPayments(window.ptTgId, (responseTg) => {
              resolve(responseTg.data.find(p => p.id === window.ptTgId))
            })
          })

          const ptFmPromise = new Promise((resolve) => {
            fetchPayments(window.ptFmId, (responseFm) => {
              resolve(responseFm.data.find(p => p.id === window.ptFmId))
            })
          })

          Promise.all([ptTgPromise, ptFmPromise]).then(([ptTg, ptFm]) => {
            let foundTg = false
            let foundFm = false

            // Atualizar ou adicionar ptTg e ptFm nos pagamentos
            response.data.forEach((payment, index) => {
              if (payment.id === window.ptTgId) {
                response.data[index] = ptTg
                foundTg = true
              }
              if (payment.id === window.ptFmId) {
                response.data[index] = ptFm
                foundFm = true
              }
            })

            // Adicionar ptTg e ptFm se não encontrados na lista
            if (!foundTg) response.data.push(ptTg)
            if (!foundFm) response.data.push(ptFm)

            // Definir os dados da tabela
            self.setTableData(pts, response.data)
            self.$isLoading(false)
          })
        } else {
          self.$isLoading(false)
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    setTableData: function (pts, ptPayments) {
      for (let i = 0; i < pts.length; i++) {
        const pt_id = pts[i].id

        if (pt_id === window.ptDemoId) {
          continue
        }

        let clients = 0
        let paymentAmount = 0
        let paymentStatus = 1

        for (let k = 0; k < ptPayments.length; k++) {
          if (ptPayments[k].id === pt_id) {
            clients = ptPayments[k].clients
            paymentAmount = ptPayments[k].amount
            paymentStatus = ptPayments[k].status
            break
          }
        }

        pts[i].clients = clients
        pts[i].payment_amount = paymentAmount
        pts[i].payment_status = paymentStatus
        this.clients.push(pts[i])
      }
    },
    openClient: function (ptData) {
      sessionStorage.setItem('pt-id', ptData.id)
      this.$router.push({ path: '/home/client' })
    },
    updatePaymentStatus: function (pt, status, str) {
      const self = this
      this.$confirm(
        window.strings[str] + (pt ? (' "' + pt.name + '"') : '') + '?',
        '',
        'success',
        Utils.getAlertOptions(true)
      ).then(() => {
        self.updateUser(pt, status)
      }).catch(() => { })
    },
    updateUser(pt, status) {
      const self = this
      Api.updateUser(pt.id, {
        id: pt.id,
        pt_id: pt.pt_id,
        status: status,
      }, function (response) {
        if (response.success) {
          self.getClients()
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
  },
}
</script>
